import React from "react";
import "./ugmkprodigies.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs";
import TopComponent from "../../Components/TopComponent";

const UgmkProdigies = () => {
  return (
    <>
      <section className="ContactBanner">
        <TopComponent
          title={"UGMK Prodigies"}
          breadcrumb={"UGMK Prodigies"}
          img={"/bg/contact.png"}
          mobileTitle={
            <>
              UGMK
              <br />
              Prdigies
            </>
          } 
          mbImg={"/bg/contact.png"}
        />
      </section>
    </>
  );
};

export default UgmkProdigies;
