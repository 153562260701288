import React from "react";
import "./error.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import TopComponent from "../../Components/TopComponent";

const PageNotFound = () => {
  return (
    <>
      <section className="ContactBanner">
        {/* <div className="BannerImg">
                    <img src={icons.Contactbanner} alt="" title="" />
                    <div className="innerbannerContent">
                        <Container className="custom-container">
                            <div className="breadcrumb-wrapper !mt-[-12vh]  md:!mt-[-5vh]">
                                <ul>
                                    <li>
                                        <Link to='/'>Home</Link>
                                    </li>
                                    <li>/</li>
                                    <li>
                                    Page not found
                                    </li>
                                </ul>
                            </div>
                            <h2> Page not found</h2>
                        </Container>
                    </div>
                </div> */}
        <TopComponent
          title={"Page Not Found"}
          breadcrumb={"Page Not Found"}
          img={"/bg/contact.png"}
          mobileTitle={
            <>
              Page Not
              <br /> Found
            </>
          } 
          mbImg={"/bg/contact.png"}
        />
      </section>
    </>
  );
};

export default PageNotFound;
