import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { icons } from "../../../Assets/imageConstants";
import { Link, useNavigate } from "react-router-dom";
import WhiteBtn from "../../../Components/WhiteBtn";
import AOS from "aos";
const UgmkAcademy = () => {
  let navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1500, // Duration of animation (1.5 seconds)
      once: false, // Animation should happen every time the section enters the viewport
    });
  }, []);

  return (
    <>
      <div className="homeBanner !min-h-screen ">
        <div className="bannerImg">
          <Container className="custom-container">
            <div
              className="bannerContent !mt-90px-mvw md:!mt-0"
              data-aos="fade-up" // AOS animation for text container
              data-aos-duration="2000" // Increased duration for a smoother effect (2 seconds)
              data-aos-offset="150"
            >
              <div className="mainHeading">
                <h1>A legacy</h1>
                <h1 className="!ml-54px-mvw md:!ml-100px-vw">of music</h1>
              </div>
              <div className="bottomArea">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  <br className="md:block hidden" />
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the <br className="md:block hidden" />
                  1500s, when an unknown printer took a galley of type.
                </p>

                <div className="md:mt-60px-vw mt-20px-mvw flex md:items-start items-center justify-center md:justify-start">
                  <WhiteBtn
                    title={"Know More"}
                    onClick={() => navigate("/about-us")}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          className="mobEnrollbtn !z-50"
          onClick={() => navigate("/contact-us")}
        >
          ENROLL NOW
        </div>
      </div>
    </>
  );
};

export default UgmkAcademy;
