import React, { useEffect, useRef } from "react";
import ustadjiiImg from "../../Assets/img/Ustadji_with_his_sons-removebg-preview.png";
import { useLocation } from "react-router-dom";
const Introduction = () => {

  return (
    <div className="overflow-x-hidden bg-[#FAF6F0]" >
      <div className="relative">
        <div className="top-[1vh] left-0 text-[16vw] md:text-[9.375vw] absolute opacity-10 font-karla font-extrabold text-[#D5B376] ">
          UGMK Academy
        </div>
      </div>
      <div className="grid md:grid-cols-[40%_60%] grid-cols-1 w-full pt-[6.61vw] px-[4.27vw] md:px-0"
        
        data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
      >
        <div className="flex md:pl-[7.29vw] flex-col w-full">
          <div className="relative pb-[6.93vw] md:pb-[2.85vh]">
            <div className="font-extrabold text-[10.67vw]  md:text-[6.25vw] font-garamond text-[#8C1211]"
      
          >
              Introduction
            </div>
          </div>
          <div className="relative w-full md:hidden block ">
          <div className="w-[60.52vw] overflow-hidden absolute left-0 right-[15vw] bottom-0 md:block hidden">
            <img src={ustadjiiImg} alt="" className="w-full h-full" />
          </div>
          {/* For mobile view, you can add this img tag directly in the single column */}
          <img src={ustadjiiImg} alt="" className="w-full h-auto md:hidden" />
        </div>
          <div className="flex pt-[6.4vw] md:pt-0 flex-col pb-[4.84vh] items-start text-[4.35vw] md:text-[1.25vw] text-[#1E1E1E] font-karla font-medium  ">
            <p>
              With Padma Vibhushan Ustad Ghulam Mustafa Khan’s dedication and
              love towards music as its core philosophy, the Ustad Ghulam
              Mustafa Khan Academy UGMK offers impetus to anyone who dreams to
              pursue music with utmost assiduity and persistence.
            </p>
            <p>
              Hailing from a family of revered musicians, with his maternal
              grandfather being Ustad Inayat Hussain Khan, music was the legacy
              of his family that was passed on from one generation to another.
            </p>
            <p>
              From online sessions to offline classes, from extensive and
              in-depth courses, to short-term skill-honing programmes — we have
              something for anyone who breathes and worships music like we do.
            </p>
          </div>
        </div>
        <div className="hidden md:block relative w-full">
          <div className="w-[60.52vw] overflow-hidden absolute left-0 right-[15vw] bottom-0 md:block hidden">
            <img src={ustadjiiImg} alt="" className="w-full h-full" />
          </div>
          {/* For mobile view, you can add this img tag directly in the single column */}
          <img src={ustadjiiImg} alt="" className="w-full h-auto md:hidden" />
        </div>
      </div>
      <div  >

      </div>
    </div>
  );
};

export default Introduction;
