import React from 'react'
import bgImg from '../../Assets/img/bgImgg.svg'
const OurGurusMobile = ({heading,subHeading,p1,p2,p3,image}) => {
  return (
    <div className=' w-full flex  flex-col-reverse  gap-[5.5vw]'>
  <div className='flex items-start !px-[4.27vw] justify-start flex-col  md:w-[52vw] w-full'>
    <h1 className='md:text-[2.08vw] text-[5.33vw]  pb-[1.23vh] font-karla font-semibold text-[#1E1E1E]'>{heading}</h1>
    <h1 className='md:text-[1.67vw] text-[4.8vw] pb-[2.46vh]  font-karla font-medium opacity-60'> {subHeading} </h1>
    <div className='md:text-[1.10vw] hidden md:block text-[4.27vw]  text-[#1E1E1E] font-karla font-semibold w-full md:w-[43.82vw]'>
      <p className=' pb-[2.70vh]'>{p1}</p>
      <p className=' pb-[2.70vh]'>{p2}</p>
      <p className=' '>{p3}</p>
    </div>
    <div className='text-[4.27vw] text-[#1E1E1E] font-karla font-medium w-full block md:hidden  pb-[2.70vh]'>
    <p className=' '>{p1}</p>
      <p className=' '>{p2}</p>
      <p className=' '>{p3}</p>

    </div>
  </div>

  <div className=' w-full '>
    <div className='flex items-center justify-center' >
       <img src={image} alt="" className=' w-[92vw]' />
    </div>
  </div>
</div>

  )
}

export default OurGurusMobile
