import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      // Only scroll to top if there is no hash in the URL
      if (!hash) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0); // Delay to ensure scroll runs after rendering
      }
    };

    scrollToTop();
  }, [pathname, hash]); // Add 'hash' to dependency array to track hash changes

  return null;
}

export default ScrollToTop;
