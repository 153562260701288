export const navlinks = [
    {
      id: 1,
      name: "About",
      link: "/about-us",
    },
    {
        id: 2,
        name: "UGMK Prodigies",
        link: "/ugmk-prodigies",
      },
      {
        id: 3,
        name: "Academy",
        link: "/academy",
      },
    {
      id: 4,
      name: "Success Stories",
      link: "/success-stories",
    },
    {
      id: 5,
      name: "Contact Us",
      link: "/contact-us",
    },
  ];
  
  export const ourProductsSublinks = [
    {
      id: 1,
      name: "Introduction",
      refid: "introduction",
    },
    {
      id: 2,
      name: "Our Gurus",
      refid: "gurus",
    },
    {
      id: 3,
      name: "Life at UGMK ",
      refid: "life",
    },
    {
      id: 4,
      name: "Awards",
      refid: "awards",
    },
   
  ];
  export const ourProductsSublinks2 = [
    {
      id: 1,
      name: "How it works",
      refid: "academy",
      link:"/academy"
    },
    {
      id: 2,
      name: "Why Us",
      refid: "academy",
      link:"/academy"
    },
    {
      id: 3,
      name: "Courses ",
      refid: "academy",
      link:"/academy"
    },
    {
      id: 4,
      name: "Workshops",
      refid: "academy",
      link:"/academy"
    },
    {
        id: 5,
        name: "Media/Gallary",
        refid: "academy",
        link:"/academy"
      }
  ];
  