import React, { useEffect } from "react";
import bgImg from "../../Assets/img/hasanbgImg.png"; import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";

const OurGurusSub2 = ({ heading, subHeading, p1, p2, p3, image }) => { 

  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);
  return (
    <div className="px-[7.29vw] w-full flex  flex-col-reverse  md:flex-row gap-[5.5vw] pt-[12.41vh] "
      
      data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
    >
      <div className="flex items-start justify-start flex-col   md:w-[50.82vw] w-full">
        <h1 className="md:text-[2.08vw] text-[5.33vw]  pb-[0.74vh] font-karla font-semibold text-[#1E1E1E]">
          {heading}
        </h1>
        <h1 className="md:text-[1.67vw] text-[4.8vw] pb-[3.70vh]  font-karla font-medium opacity-60">
          {" "}
          {subHeading}{" "}
        </h1>
        <div className="md:text-[1.25vw] hidden md:block text-[4.27vw]  text-[#1E1E1E] font-karla font-medium w-full md:w-[45.82vw]">
          <p className=" pb-[2.70vh]">{p1}</p>
          <p className=" pb-[2.70vh]">{p2}</p>
          <p className=" ">{p3}</p>
        </div>
        <div className="text-[4.27vw] text-[#1E1E1E] font-karla font-medium w-full block md:hidden  pb-[2.70vh]">
          <p>
         Hailing from the coveted Rampur-Sahaswan Gharana, Ghulam Qadir Khan started his training in music at the age of 5. Having initially learnt from his grandfather, late Ustad Waris Hussain Khan, he later received the able guidance of his father, Padmabhushan Ustad Ghulam Mustafa Khan. The musician has toured across the world for Sufi and fusion music, besides solo classical music concerts. An ace in Hindustani Classical, Geet, Ghazals and Bhajans, Ghulam Qadir Khan has also worked on several Bollywood projects with composers AR Rahman, Shankar-Ehsaan-Loy and Amit Trivedi, among others. Some of his playback outings include Piya Haji Ali (Fiza), Chupke Se (Saathiya), Tere Bina (Guru), Haa Rehem (Aamir), Maula Mere (Anwar), Al-Madath-Maula (Mangal Pandey) and Noor-Un-Ala-Noor (Meenaxi), among others.

          </p>
        </div>
      </div>

      <div className="md:w-[50%]  md:block hidden w-full">
        <div className="relative inset-0">
          <div className="md:w-[31.54vw]  md:h-[70.94vh]">
            <img src={bgImg} alt="" className="h-full w-full" />
          </div>
          <div className="absolute md:top-[-10%] top-[-37%] left-0 md:left-[-15%] w-full md:!w-[37.6vw]  h-[73.80vh]">
            <img
              src={image}
              alt=""
              className="md:!w-[37.6vw] w-full !h-[73.80vh] "
            />
          </div>
        </div>
      </div>

      <div className="md:w-[50%] md:hidden block w-full">
        <div className="relative inset-0">
          <div className="md:w-[32vw]  w-full h-[350px] md:!h-[70.94vh]">
            <img src={bgImg} alt="" className="h-full w-full" />
          </div>
          <div className="absolute md:top-[-10%] top-[-12%] left-0 md:left-[-15%] w-full md:!w-[37.6vw]  h-[73.80vh]">
            <img
              src={image}
              alt=""
              className="md:!w-[37.6vw] h-[368px] w-[344px]  "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGurusSub2;
