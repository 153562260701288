import React, { useEffect, useState } from "react";
import { icons } from "../../Assets/imageConstants";
import { initialImages } from "../../lib/homeData";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; 

const TestimonialMobile = () => {
  const [images, setImages] = useState(initialImages);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      moveItems();
    }, 15000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);

  const moveItems = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  };

  const handleItemClick = (index) => {
    setCurrentIndex(index);
  };

  const getCircularIndex = (index, length) => {
    return (index + length) % length;
  };

  return (
    <>
      <section className="bg-[#FAF6F0] w-full py-[16vw] relative">
        <div className="text-center relative">
        <h3 className="text-[#D5B376] hidden md:block opacity-10 font-karla font-extrabold  text-[16vw] absolute top-[-8vw] left-0 right-0">
            Happy Student
          </h3> 
          <h3 className="text-[#D5B376] block md:hidden opacity-10 font-karla font-extrabold  text-[16vw] absolute top-[-8vw] left-0 right-0">
            Happy Stud
          </h3>
          <h2 className=" text-[10.67vw] font-garamond text-[#8C1211] font-extrabold mb-8">Testimonials</h2>
        </div>

        <div className="w-full flex flex-col items-center justify-center">
          <div className="relative w-full">
            <div className="relative text-center mb-4 px-16px-mvw pt-40px-mvw">
              <div className="relative mx-auto w-full text-karla-medium-16 font-karla font-medium rounded-full">
                <img src={icons.Rightquote} alt="Quote" className="absolute left-0 !top-[-4vw] w-30px-mvw " />
                <p className="text-[4.27vw] py-15px-mvw text-[#1E1E1E] font-semibold">{images[currentIndex].desc}</p>
                <img src={icons.Leftquote} alt="Quote" className="absolute right-0 !bottom-[-4vw]  w-30px-mvw" />
              </div>
            </div>

            {/* Carousel */}
            <div className="w-[100vw] flex pt-15px-mvw justify-center ">
              <div className="flex items-center space-x-16px-mvw overflow-x-hidden">
                {Array(5).fill().map((_, index) => {
                  const imageItem = images[getCircularIndex(currentIndex + index - 2, images.length)];
                  const isCenter = index === 2;
                  const imageSize = isCenter ? 'w-[30.33vw]' : index === 0 || index === 4 ? 'w-43px-mvw' : 'w-68px-mvw';

                  return (
                    <div
                      key={index}
                      className={`transition-transform ${isCenter ? 'scale-100' : 'scale-100'} ${imageSize} rounded-full`}
                      onClick={() => handleItemClick(getCircularIndex(currentIndex + index - 2, images.length))}
                    >
                      <img src={imageItem.img} alt="testimonial" className="rounded-full object-cover" />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Description */}
            <div className="text-center mt-4 flex flex-col items-center justify-center gap-8px-mvw">
              <h2 className="text-[4.8vw] font-semibold font-karla text-[#1E1E1E]">{images[currentIndex].name}</h2>
              <h2 className="text-[4.27vw] font-medium font-karla opacity-60 text-[#1E1E1E]">{images[currentIndex].post}</h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialMobile;
