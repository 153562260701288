import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import "../Privacypolicy/privacyPolicy.scss";
import BreadCrumbs from "../../Components/BreadCrumbs";
import TopComponent from "../../Components/TopComponent";

const RefundPolicy = () => {
  return (
    <div>
      <section className="ContactBanner">
        <TopComponent
          title={"Refund Policy"}
          breadcrumb={"Refund Policy"} 
          mbImg={"/mobile/mobile_refund.png"}
          img={"/bg/refund.png"}
          mobileTitle={
            <>
              Refund <br /> Policy
            </>
          }
        />
      </section>
      <section className="PrivacyPolicy">
        <Container className="custom-container">
          <div className="PrivacyContent">
            <Row className="pdBottom">
              <Col md={3}>
                <div className="privacyHeading">Personal Statement</div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </Col>
            </Row>
            <Row className="pdBottom">
              <Col md={3}>
                <div className="privacyHeading">Why do we use cookies?</div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </Col>
            </Row>
            <Row className="pdBottom">
              <Col md={3}>
                <div className="privacyHeading">
                  What information do we gather?
                </div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </Col>
            </Row>
            <Row className="pdBottom">
              <Col md={3}>
                <div className="privacyHeading">
                  What third-parties do we share your information with?
                </div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </Col>
            </Row>
            <Row className="pdBottom">
              <Col md={3}>
                <div className="privacyHeading">Website Media</div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </Col>
            </Row>
            <Row className="pdBottom">
              <Col md={3}>
                <div className="privacyHeading">
                  Disclosure of your information
                </div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="privacyHeading">Updates</div>
              </Col>
              <Col md={9}>
                <div className="PrivacyRightContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default RefundPolicy;
