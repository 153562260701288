import {icons} from '../Assets/imageConstants';
import img1 from '../Assets/ImgWithBg/prodigy 1.png';
import img2 from '../Assets/ImgWithBg/prodigy 2.png';
import img3 from '../Assets/ImgWithBg/prodigy 3.png';
export const ugmk_prodigies = [
    {
        img:img1,
        name:'A R RAHMAN',
        description:'(Indian musical composer and record producer)'
        
    },
    {
        img:img2,
        name:'HARIHARAN',
        description:'(Indian singer-songwriter and actor)'

    },
    {
        img:img3,
        name:'SONU NIGAM',
        description:'(Indian music director and singer)'

    },
]

export const initialImages = [
    {
      img: icons.Thumbnail,
      post:"(Student, Singer)",
      name: "RASHA THADANI",
      desc: `I have been learning from Qadir sir since I was 8. Our classes have always been a perfect balance of knowledge and fun. 
             From learning a variety of songs to understanding the technicalities of each sur-scale or note — everything I know about music today is because of Qadir sir. 
             He has not only been a constant inspiration for all his students, but is also someone I have looked up to. 
             His compassion, patience, and leniency with each student is heartwarming. I feel proud that I’m a student of Qadir sir. He’s my guru and family.`
    },
    {
      img: icons.Thumbnail1,
      post:"(Teacher, Singer)",
      name: "SHEFALI ALVARES",
      desc: `Learning music with Qadir sir has been nothing short of a transformative experience. 
             His unique teaching methods made even the most complex musical concepts easy to grasp. 
             From classical ragas to modern melodies, every lesson is a beautiful blend of tradition and innovation. 
             Qadir sir encourages every student to explore their own musical journey while ensuring a solid foundation. 
             His dedication and endless patience make learning from him a joyful experience. I feel fortunate to call him my teacher.`
    },
    {
      img: icons.Thumbnail2,
      post:"(Student, Singer)",
      name: "PRIYANI PANDIT",
      desc: `Qadir sir is not only a teacher but a true mentor who guides his students with immense patience and understanding. 
             His teaching goes beyond technical knowledge and delves into the soul of music, making sure that every student not only learns but lives the music. 
             Whether it’s breaking down complicated scales or helping us perform with confidence, Qadir sir always knows the best way to explain things. 
             His lessons are filled with wisdom, and each one brings us closer to mastering this beautiful art.`
    },
    {
      img: icons.Thumbnail3,
      post:"(Student, Singer)",
      name: "ARMAAN MALIK",
      desc: `I’ve been blessed to have Qadir sir as my guide from a very young age. His teaching style is exceptional, blending technical mastery with emotional depth. 
             Every lesson is filled with valuable insights, and he always encourages us to strive for excellence. 
             Under his guidance, I’ve learned to approach music not just as a performer but as an artist. His patience and kindness are unmatched, and I truly feel lucky to call him my guru.`
    }
  ];