import {
    Routes,
    Route,
  } from "react-router-dom";
  import * as Screens from '../Container';
  import * as navigate from "./RouteConstent"; 
  import MobileSidebar from "../Components/MobileSidebar";
  
  export const AppRoutes = () => {
    return (
        <Routes>
          <Route path={navigate.HOME} element={<Screens.Home />} />  
          <Route path={navigate.ABOUT} element={<Screens.AboutPage />} />
          <Route path={navigate.CONTACTUS} element={<Screens.ContactUs />} />
          <Route path={navigate.TERMSUSE} element={<Screens.TermsOfUse />} />
          <Route path={navigate.PRIVACYPOLICE} element={<Screens.PrivacyPolicy />} />
          <Route path={navigate.REFUNDPOLICY} element={<Screens.RefundPolicy />} />
          <Route path={navigate.FAQS} element={<Screens.FrequentlyAskedQue />} /> 
          <Route path={navigate.UGMKPRODIGIES} element={<Screens.UgmkProdigies />} />
          <Route path={navigate.ACADEMY} element={<Screens.Academy />} />
          <Route path={navigate.SUCCESSSTORY} element={<Screens.SuccessStory />} />   
          <Route path="*" element={<Screens.PageNotFound />} />   
           

        </Routes>
    )
  }