import React from "react";
import "./success.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs";
import TopComponent from "../../Components/TopComponent";

const SuccessStory = () => {
  return (
    <>
      <section className="ContactBanner">
        <TopComponent
          title={"Success Story"}
          breadcrumb={"Success Story"}
          img={"/bg/contact.png"}
          mobileTitle={
            <>
              Success
              <br /> Story 
            </>
          } 
          mbImg={"/bg/contact.png"}
        />
      </section>
    </>
  );
};

export default SuccessStory;
