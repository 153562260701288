export const HOME = '/';
export const ABOUT = '/about-us';
export const CONTACTUS = '/contact-us';
export const TERMSUSE = '/terms-of-use';
export const PRIVACYPOLICE = '/privacy-policy';
export const REFUNDPOLICY = '/refund-policy';
export const FAQS = '/faqs';
export const UGMKPRODIGIES = '/ugmk-prodigies';
export const ACADEMY = '/academy';
export const SUCCESSSTORY = '/success-stories'
export const mobile = '/mobile'