import React, { useEffect } from "react";
import bgImg from "../../Assets/img/bgImgg.svg";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";
const OurGurusSub = ({ heading, subHeading, p1, p2, p3, image }) => {
  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);

  return (
    <div
      className="px-[7.29vw] w-full flex  flex-col-reverse  md:flex-row gap-[5.5vw] md:gap-[2vw] pt-[12.41vh] "
      data-aos="fade-up" // AOS animation for text container
      data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
      data-aos-offset="150"
    >
      <div className="flex items-start justify-start flex-col  md:w-[65vw] w-full">
        <h1 className="md:text-[2.08vw] text-[5.33vw]  pb-[0.74vh] font-karla font-semibold text-[#1E1E1E]">
          {heading}
        </h1>
        <h1 className="md:text-[1.67vw] text-[4.8vw] pb-[3.70vh]  font-karla font-medium opacity-60">
          {" "}
          {subHeading}{" "}
        </h1>
        <div className="md:text-[1.25vw] hidden md:block text-[4.27vw]  text-[#1E1E1E] font-karla font-semibold w-full md:w-[50.82vw]">
          <p className=" pb-[2.70vh]">{p1}</p>
          <p className=" pb-[2.70vh]">{p2}</p>
          <p className=" ">{p3}</p>
        </div>
        <div className="text-[4.27vw] text-[#1E1E1E] font-karla font-medium w-full block md:hidden  pb-[2.70vh]">
          <p>
            The UGMK Academy is run by Ustad Ghulam Mustafa Khan’s family, and
            hence is an infallible centre for all things music. Our gurus -
            Ustad Khan’s sons Murtuza Mustafa, Qadir Mustafa and Hasan Mustafa -
            have nurtured several musicians, who are among the most revered
            names in the world of music today. The UGMK Academy is run by Ustad
            Ghulam Mustafa Khan’s family, and hence is an infallible centre for
            all things music. Our gurus
          </p>
        </div>
      </div>

      <div className="md:w-[47%] w-full pt-[3vh] md:pt-[0vh]">
        <div className="relative inset-0">
          <div className="md:w-[33.54vw]     md:h-[66.94vh]">
            <img src={bgImg} alt="" className="h-full w-full" />
          </div>
          <div className="absolute md:top-[-10%]  top-[-32%] left-0 md:left-[16%] w-full md:w-[26.15vw] h-[70.80vh] md:h-[73.80vh]">
            <img
              src={image}
              alt=""
              className="md:w-[26.15vw] w-full h-[70.80vh] md:h-[73.80vh]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGurusSub;
