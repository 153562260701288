import React, { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { Container, Nav } from "react-bootstrap";
import { icons } from "../Assets/imageConstants";
import { Link } from "react-router-dom";
import "./footer.scss";
import licom from "../Assets/ImgWithBg/location.svg"
import message from "../Assets/ImgWithBg/message.svg"
import call from "../Assets/ImgWithBg/call.svg"

const address1 = "205,Kalpana Apartment,";
const address2= "New Kantwadi Road, Off Perry Cross Road Bandra West, Mumbai-400050, India";
const address = `205,Kalpana Apartment, New Kantwadi Road, Off Perry Cross Road Bandra West, Mumbai-400050, India`;
const contact = `+91 9820410648`;

const Footer = () => {
  const [showQuickLinks, setShowQuickLinks] = useState(true);
  const [showLegalities, setShowLegalities] = useState(false);

  const toggleQuickLinks = () => setShowQuickLinks(!showQuickLinks);
  const toggleLegalities = () => setShowLegalities(!showLegalities);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling
    });
  };
// section
  return (
    <section className="footerSection">
      <Container className="custom-container">
        <div className="gulamFooterwrapper">
          <div className="socialMedia !py-40px-vw md:py-0">
            <div className="FooterLogo ">
              <Link to="/">
                <img src={icons.Logo} alt="" title="" />
              </Link>
            </div>
            <ul>
              {/* comment */}
              <li className="!pt-24px-vw md:pt-0">
                <Nav.Link href="#link">
                  <img src={icons.Spotify} alt="" title="" />
                </Nav.Link>
                <Nav.Link href="https://twitter.com/" target="_blank">
                  <img src={icons.Socialicon} alt="" title="" />
                </Nav.Link>
                <Nav.Link href="https://www.instagram.com/" target="_blank">
                  <img src={icons.Instagram} alt="" title="" />
                </Nav.Link>
                <Nav.Link href="https://www.facebook.com/your_page_id" target="_blank">
                  <img src={icons.Facebook} alt="" title="" />
                </Nav.Link>
              </li>
            </ul>
          </div>

          <div className="FooterLinks responsiveLinks">
            <div className="flex justify-between w-[50vw] md:w-auto" onClick={toggleQuickLinks}>
              <h4>QUICK LINKS</h4>
              <span className="block md:hidden font-medium text-[#8C1211]" onClick={toggleQuickLinks}>
                {showQuickLinks ? <BiMinus /> : <BiPlus />}
              </span>
            </div>
            <ul className="hidden md:block ">
              <li><Link to="/about-us">About</Link></li>
              <li><Link to="#">Courses</Link></li>
              <li><Link to="#">News & Events</Link></li>
            </ul>
            <ul className={`md:hidden !pt-[2vh] ${showQuickLinks ? 'block' : 'hidden'}`}>
              <li ><Link to="/about-us"><div className="!text-[4vw] pb-4px-mvw md:pb-0">About</div></Link></li>
              <li ><Link to="#"><div className="!text-[4vw] pb-4px-mvw md:pb-0">Courses</div></Link></li>
              <li ><Link to="#"><div className="!text-[4vw]  md:pb-0">News & Events</div></Link></li>
            </ul>
          </div>

          {/* LEGALITIES Section with Toggle */}
          <div className="FooterLinks responsiveLinks">
            <div className="flex justify-between w-[50vw] md:w-auto" onClick={toggleLegalities}>
              <h4 className="pb-30px-mvw md:pb-0">LEGALITIES</h4>
              <span className="!block md:!hidden font-medium text-[#8C1211]" onClick={toggleLegalities}>
                {showLegalities ? <BiMinus /> : <BiPlus />}
              </span>
            </div>
            <ul className="hidden md:block">
              <li><Link to="/privacy-policy" >Privacy Policy</Link></li>
              <li><Link to="/terms-of-use">Terms Of Use</Link></li>
              <li><Link to="/refund-policy">Refund Policy</Link></li>
              <li><Link to="/faqs">FAQs</Link></li>
            </ul>
            <ul className={`md:hidden !pt-[2vh] ${showLegalities ? 'block' : 'hidden'}`}>
              <li><Link to="/privacy-policy"><div className="!text-[4vw] pb-4px-mvw md:pb-0 ">Privacy Policy</div></Link></li>
              <li><Link to="/terms-of-use"><div className="!text-[4vw] pb-4px-mvw md:pb-0">Terms Of Use</div></Link></li>
              <li><Link to="/refund-policy"><div className="!text-[4vw] pb-4px-mvw md:pb-0">Refund Policy</div></Link></li>
              <li><Link to="/faqs"><div className="!text-[4vw] pb-4px-mvw md:pb-0">FAQs</div></Link></li>
            </ul>
          </div>

          <div className="FooterLinks hidden md:block flex flex-col items-start gap-y-10px-mvw md:gap-y-0">
            <h4 className="">CONTACT</h4>
            <div className="addressBox ">
              <div className="icon pt-[1vh] md:pt-2">
                <img src={icons.Location} alt="" title="" className=" !w-[8vw] md:!w-[2vw]" />
              </div>
              <div className="location">
                <Nav.Link
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                  target="_blank"
                >
                  <div className="text-[4.27vw] md:text-[1vw]">{address}</div>
                  
                </Nav.Link>
              </div>
            </div>
            <div className="addressBox">
              <div className="icon md:pt-2">
                <img src={icons.Email} alt="" title="" className="!w-[3.5vw] md:!w-[1vw]" />
              </div>
              <div className="location">
                <Nav.Link href="mailto:ugmkacademy@gmail.com" target="_blank">
                  <div className="text-[4.27vw]  md:text-[1vw]">ugmkacademy@gmail.com</div>
                </Nav.Link>
              </div>
            </div>
            <div className="addressBox">
              <div className="icon md:pt-1">
                <img src={icons.Call} alt="" title="" className="!w-[3.5vw] md:!w-[1vw]"/>
              </div>
              <div className="location conumber">
                <Nav.Link href={`tel:${contact}`}><div className="text-[4.27vw]  md:text-[1vw]">+91 7021955413 /</div></Nav.Link>
                <div className="secondCoNumber">
                  <Nav.Link href={`tel:${contact}`}><div className="text-[4.27vw]  md:text-[1vw]">+91 9820410648</div></Nav.Link>
                </div>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="FooterLinks  md:hidden block flex flex-col items-start gap-y-10px-mvw md:gap-y-0">
            <h4 className="">CONTACT</h4>
          <div className="mt-[2vh] ">
            <div className="flex gap-14px-mvw items-center">
              <div className="mt-[-5.5vh]">
                <img src={licom} alt="" title="" className=" h-24px-mvw !w-35px-mvw" />
              </div>
              <div className="">
                <Nav.Link
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                  target="_blank"
                >
                  <div className="text-[4.27vw] md:text-[1vw]">{address1}
                    <br />
                  {address2}
                  </div>
                  
                </Nav.Link>
              </div>
            </div>
            <div className="flex mt-16px-mvw gap-10px-mvw items-center ">
              <div className="">
                <img src={message} alt="" title="" className="!w-22px-mvw " />
              </div>
              <div className="">
                <Nav.Link href="mailto:ugmkacademy@gmail.com" target="_blank">
                  <div className="text-[4.27vw]  md:text-[1vw]">ugmkacademy@gmail.com</div>
                </Nav.Link>
              </div>
            </div>
            <div className="flex mt-16px-mvw gap-10px-mvw items-center">
              <div className="">
                <img src={call} alt="" title="" className="!w-22px-mvw"/>
              </div>
              <div className="flex items-center gap-[1vw]" >
               <div>
               <Nav.Link href={`tel:${contact}`}><div className="text-[4.27vw]  ">+91 7021955413 /</div></Nav.Link>
               </div>
                <div className="">
                  <Nav.Link href={`tel:${contact}`}><div className="text-[4.27vw]  ">+91 9820410648</div></Nav.Link>
                </div>
              </div>
            </div>
          </div>
         
          </div>


        </div>
        <div className="md:hidden block w-full h-[0.2vh] border-y-[0.5px] border-[#d5b376] mt-[2vh] mb-[3vh] opacity-65">

        </div>
        <div className="!hidden md:!flex footerBottom py-[5vh] md:py-[0vh]">
          <div className="innerBox  !text-start">
            Ustad Ghulam Mustafa Khan &copy; All Rights Reserved - 2024
          </div>
          <div className="innerBox2 !text-start py-[5vh] md:py-[0vh] cursor-pointer">
          <span> Crafted by </span>
         <a href="https://www.togglehead.in/" target="_blank" className="no-underline text-[#1E1E1E] hover:text-red-600">
    Togglehead
          </a>
</div>

        </div>
        {/* mobile */}
      
        <div className="md:!hidden !flex flex-col gap-12px-mvw  md:py-[0vh] !pb-40px-mvw">
          <div className=" !text-start text-14px-mvw font-karla ">
            Ustad Ghulam Mustafa Khan &copy; All Rights Reserved - 2024
          </div>
          <div className=" !text-start text-14px-mvw font-karla  cursor-pointer ">
  <span> Crafted by </span>
  <a href="https://www.togglehead.in/" target="_blank" className="no-underline text-[#1E1E1E] hover:text-red-600">
    Togglehead
  </a>
  
</div>

        </div>
      </Container>
      <div className="md:hidden block h-40px-mvw w-full bg-[#FAF6F0]">

      </div>

    </section>
    
  );
};

export default Footer;
