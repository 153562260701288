import React from "react";
import "./academy.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs";
import TopComponent from "../../Components/TopComponent";


const Academy = () => {
    return (
        <>
            <section className="ContactBanner">
            <TopComponent 
             title={"Academy"}
             breadcrumb={"Academy"}
             img={"/bg/contact.png"}
             mobileTitle={"Academy"} 
             mbImg={"/bg/contact.png"}
              />
            </section>

        </>
    )
}

export default Academy