import React, { useEffect, useState } from "react";
import "./contactUs.scss";
import { Container, Row, Col } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import { contactUsFormValidation } from "../../Utils/validation";
import { toast } from "react-toastify"; // Ensure you've installed react-toastify
import WhiteBtn from "../../Components/WhiteBtn";
import TopComponent from "../../Components/TopComponent";
import AOS from "aos";
const ContactUs = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    message: "", 
    course:"",
  });
  const [errorMsg, setErrorMsg] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;
    let newValue = { [name]: value };
    let { errors } = contactUsFormValidation(newValue);
    setDetails({ ...details, [name]: value });
    setErrorMsg({ ...errorMsg, ...errors });
  };

  const handleSubmit = () => {
    let { errors, isValid } = contactUsFormValidation(details);
    if (isValid) {
      // Display a toast notification
      toast.success("Form Submitted Successfully!");

      // Clear the form fields
      setDetails({ name: "", email: "", phone: "", location: "", message: "",course:"" });
      setErrorMsg({});
    } else {
      setErrorMsg({ ...errorMsg, ...errors });
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1500, // Duration of animation (1.5 seconds)
      once: false, // Animation should happen every time the section enters the viewport
    });
  }, []);

  return (
    <>
      <section className="ContactBanner">
        <TopComponent
          title={"Contact Us"}
          breadcrumb={"Contact Us"}
          img={"/bg/contact.png"}
          mobileTitle={"Contact Us"}
          mbImg={"/bg/contact.png"}
        />
      </section>
      <section
        className="GetinTouchContact"
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="2000" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <div className="ContactTItle">
          <h3>Contact Us</h3>
          <h2 className="heading">Get In Touch</h2>
        </div>
        <Container className="custom-container">
          <div className="locationOuter">
            <div className="locationBox !pr-[4vw] ">
              <div className="IconBox flex items-center justify-center !h-[11.5vh] md:!h-[5.05vw] md:!w-[5.05vw]">
                <img
                  src={icons.Location}
                  alt=""
                  title=""
                  className="md:!w-[1.97vw]"
                />
              </div>
              <h4>LOCATION</h4>

              <p className="hover:!text-[#8C1211] text-[1.24vw] no-underline cursor-pointer">
                205, Kalpana Apartment,New Kantwadi Road, Off Perry Cross Road
                Bandra West, Mumbai-400050, India
              </p>

              <p>
                <Link>Google Maps</Link>
              </p>
            </div>
            <div className="locationBox">
              <div className="IconBox flex items-center justify-center !h-[11.5vh] md:!h-[5.05vw] md:!w-[5.05vw]">
                <img
                  src={icons.Email}
                  alt=""
                  title=""
                  className="md:!w-[1.97vw]"
                />
              </div>
              <h4>EMAIL</h4>
              <a
                href="mailto:ustadghulammustafakhan@gmail.com"
                className="no-underline text-center flex items-center justify-center"
              >
                <div className="text-[#454444] lg:text-[1.24vw] text-center !mt-[2vh] font-medium hover:text-[#8C1211] no-underline cursor-pointer">
                  ugmkacademy@gmail.com
                </div>
              </a>
            </div>
            <div className="locationBox">
              <div className="IconBox flex items-center justify-center !h-[11.5vh] md:!h-[5.05vw] md:!w-[5.05vw]">
                <img
                  src={icons.Call}
                  alt=""
                  title=""
                  className="md:!w-[1.97vw]"
                />
              </div>
              <h4>PHONE NO.</h4>
              <div className="flex items-center justify-center ">
                <a href="tel:9820410648" className="no-underline">
                  <div className="text-[#454444] lg:text-[1.24vw] !mt-[2vh] font-medium hover:text-[#8C1211] no-underline cursor-pointer">
                    +91 7021955413 /
                  </div>
                </a>
                <a href="tel:9833786786" className="no-underline">
                  <div className="text-[#454444] lg:text-[1.24vw] !mt-[2vh] font-medium hover:text-[#8C1211] no-underline cursor-pointer">
                    +91 9820410648
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        className="WriteToUs padd-btm"
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="2000" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <Container className="custom-container">
          <div className="WriteInner">
            <h2>Write To Us</h2>
            <Row>
              <Col sm={6}>
                <div className="inputBox">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={details.name}
                    onChange={handleChange}
                  />
                  <small className="error" style={{ fontSize: "1.1rem" }}>
                    {errorMsg.name}
                  </small>{" "}
                  {/* Increased font size */}
                </div>
              </Col>
              <Col sm={6}>
                <div className="inputBox">
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={details.email}
                    onChange={handleChange}
                  />
                  <small className="error" style={{ fontSize: "1.1rem" }}>
                    {errorMsg.email}
                  </small>{" "}
                  {/* Increased font size */}
                </div>
              </Col>
              <Col sm={4}>
                <div className="inputBox">
                  <input
                    type="number"
                    placeholder="Phone No."
                    name="phone"
                    value={details.phone}
                    onChange={handleChange}
                  />
                  <small className="error" style={{ fontSize: "1.1rem" }}>
                    {errorMsg.phone}
                  </small>{" "}
                  {/* Increased font size */}
                </div>
              </Col>
              <Col sm={4}>
                <div className="inputBox">
                  <select
                    name="location"
                    value={details.location}
                    onChange={handleChange}
                  >
                    <option value="" className="cursor-pointer">
                      Location
                    </option>
                    <option value="mumbai" className="cursor-pointer">
                      Mumbai
                    </option>
                    <option value="delhi" className="cursor-pointer">
                      Delhi
                    </option>
                  </select>
                  <small className="error" style={{ fontSize: "1.1rem" }}>
                    {errorMsg.location}
                  </small>{" "}
                  {/* Increased font size */}
                </div>
              </Col> 
              <Col sm={4}>
                <div className="inputBox">
                  <select
                    name="course"
                    value={details.course}
                    onChange={handleChange}
                  >
                    <option value="" className="cursor-pointer">
                    Select Course
                    </option>
                    <option value=" Hindustani Classic" className="cursor-pointer">
                      Hindustani Classic
                    </option>
                    <option value="Film Music" className="cursor-pointer">
                     Film Music
                    </option> 
                    <option value=" Devotional Music" className="cursor-pointer">
                  Devotional Music
                    </option>
                    <option value=" Ghazal and Semi Classical" className="cursor-pointer">
                    Ghazal and Semi Classical
                    </option>
                  </select>
                  <small className="error" style={{ fontSize: "1.1rem" }}>
                    {errorMsg.course}
                  </small>{" "}
                  {/* Increased font size */}
                </div>
              </Col>
              <Col sm={12}>
                <div className="inputBox">
                  <textarea
                    className="!mb-0"
                    placeholder="Message"
                    name="message"
                    value={details.message}
                    onChange={handleChange}
                  />
                  <small className="error" style={{ fontSize: "1.1rem" }}>
                    {errorMsg.message}
                  </small>{" "}
                  {/* Increased font size */}
                </div>
              </Col>
            </Row>
            <div className="flex items-center justify-center">
              <WhiteBtn title={"Submit"} onClick={handleSubmit} />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
