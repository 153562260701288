import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./frequentlyAskedQue.scss"; // Keep your existing styles
import TopComponent from "../../Components/TopComponent";
import AOS from "aos";
const faqs = [
  {
    question:
      "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    question:
      "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    question:
      "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    question:
      "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    question:
      "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    question:
      "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
];

const FrequentlyAskedQue = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Start with no active index

  const toggleFAQ = (index) => {
    // If the clicked index is already active, close it
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      // Close the current one first
      setActiveIndex(null);
      // Use setTimeout to delay opening the new one
      setTimeout(() => {
        setActiveIndex(index);
      }, 300); // Delay should match your CSS transition time
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1500, // Duration of animation (1.5 seconds)
      once: false, // Animation should happen every time the section enters the viewport
    });
  }, []);

  return (
    <div>
      <section className="ContactBanner">
        <TopComponent
          mobileTitle={"FAQs"}
          title={"Frequently Asked Questions"}
          breadcrumb={"FAQs"}
          mbImg={"/mobile/mobile_faq.png"}
          img={"/bg/faq.png"}
        />
      </section>

      <section
        className="FAQcontent"
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="2000" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <Container className="custom-container">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <h4
                  style={{
                    cursor: "pointer",
                    color: activeIndex === index ? "#8C1211" : "#1E1E1E",
                  }}
                >
                  {faq.question}
                </h4>
                <span className="!cursor-pointer">
                  {activeIndex === index ? (
                    <AiOutlineMinus className="text-2xl md:text-3xl font-black text-[#8C1211]" />
                  ) : (
                    <AiOutlinePlus className="text-2xl md:text-3xl font-black text-[#8C1211]" />
                  )}
                </span>
              </div>
              <div
                className={`faq-answer ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </Container>
      </section>
    </div>
  );
};

export default FrequentlyAskedQue;
