import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TopComponent = ({ img, title, mobileTitle, breadcrumb, mbImg }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the width as necessary

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="w-full h-[112.33vw] md:h-[40vw] bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${isMobile ? mbImg : img})`, // Use mbImg for mobile and img for desktop
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* Dark overlay for better text visibility */}
      <div className="absolute inset-0 opacity-50 z-10"></div>
      <div className="absolute md:top-[16vw] md:left-[7.40vw] left-[4.27vw] top-[40vw] md:text-[2vw] text-[#FAF3EB] ">
        <span
          className="text-12px-mvw md:text-karla-medium-16 text-[#FAF3EB] opacity-50 font-karla font-medium"
          onClick={() => navigate("/")}
        >
          Home
        </span>
        <span className="text-12px-mvw md:text-karla-medium-16 text-[#FAF3EB] opacity-50 font-karla font-medium px-[4px]">/</span>
        <span className="text-12px-mvw md:text-karla-medium-16 text-[#FAF3EB] opacity-100 font-karla font-medium">{breadcrumb}</span>
      </div>

      <div className="flex items-center justify-center text-[#FAF3EB] w-full h-full relative z-20">
        {/* Centered content */}
        <div className="flex flex-col items-center justify-center mt-[40vw] md:mt-[15vw]">
          <h1 className="font-garamond text-center font-extrabold text-[#FDF6EE] text-[16vw] md:text-[6.25vw]">
            {isMobile ? mobileTitle : title}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default TopComponent;
