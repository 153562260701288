import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import right from "../../Assets/img/rightarrow.png";
import left from "../../Assets/img/Leftarrow.png";
import { Container } from "react-bootstrap";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";

const LifeSlider = ({ ref3 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(null); // State to track which video is playing
  const videoRefs = useRef([]); // Use ref to store references to all video elements

  const videos = [
    {
      id: 1,
      videoId: "mdacs_CkXvw",
      url: "https://www.youtube.com/embed/mdacs_CkXvw?autoplay=1",
      title: "New Release",
      thumbnail: "/home/thumbnail.png",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
    },
    {
      id: 2,
      videoId: "mdacs_CkXvw",
      url: "https://www.youtube.com/embed/mdacs_CkXvw?autoplay=1",
      title: "The Witcher: Blood Origin",
      thumbnail: "/home/thumbnail.png",
      description:
        "Explore the origins of the Witcher universe in this epic tale of magic and monsters.",
    },
    {
      id: 3,
      videoId: "mdacs_CkXvw",
      url: "https://www.youtube.com/embed/mdacs_CkXvw?autoplay=1",
      title: "Spider-Man: A New Beginning",
      thumbnail: "/home/thumbnail.png",
      description:
        "Peter Parker's journey continues as he takes on new villains and navigates the challenges of both his superhero and personal life.",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20%",
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => {
      if (playingIndex !== null) {
        videoRefs.current[playingIndex]?.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
        setPlayingIndex(null); // Stop the current video when switching slides
      }
      setActiveIndex(next);
    },
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { 
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 600,
        settings: { 
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);

  const handlePlayClick = (index) => {
    if (playingIndex !== null) {
      // Pause any video that is currently playing
      videoRefs.current[playingIndex]?.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
    setPlayingIndex(index); // Set the index of the video being played
  };

  return (
    <div className="bg-[#FAF6F0] pb-[9.5vh]" ref={ref3}>
      <div className="UGMKheading pb-[6vh] md:pb-0">
        <div className="relative">
          <h3 className="md:text-[16.67vh] text-[8vh] text-[#D5B376] font-extrabold absolute md:top-[-65px] top-[-40px] opacity-10">
            Student Life
          </h3>
        </div>
        <Container
          className="custom-container"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <h2 className="heading !text-[10.67vw] md:!text-[6.25vw]">
            Life At UGMK
          </h2>
        </Container>
      </div>
      <Slider {...settings}>
        {videos.map((video, index) => (
          <div
            key={index}
            className={`relative !w-[85.47vw] ml-[7.27vw] md:ml-0 md:p-[1.63vw] h-[22.63vh] md:!w-[60.02vw] md:h-[70.44vh] transition-transform duration-500 ease-in-out ${
              activeIndex === index ? "scale-105 z-10" : "scale-90 opacity-70"
            }`}
          >
            {playingIndex === index ? (
              <iframe
                className="w-full h-full object-cover"
                src="https://www.youtube.com/embed/_UZhioKe8vg?controls=1&autoplay=0&showinfo=0&rel=0"
                referrerpolicy="no-referrer-when-downgrade"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : (
              <div className="relative w-full h-full">
                <img
                  src={video.thumbnail}
                  alt="thumbnail"
                  className="w-full h-full object-cover"
                />
                <div
                  onClick={() => handlePlayClick(index)}
                  className="absolute inset-0 flex items-center justify-center cursor-pointer"
                >
                  <img
                    src="/home/play_icon.png"
                    alt="Play icon"
                    className="w-[12vw] md:w-[6vw]"
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Custom Prev Arrow component
const CustomPrevArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="absolute md:left-[13vw] cursor-pointer left-[2.7vw] top-1/2 transform -translate-y-1/2 z-10 text-white rounded-full"
    >
      <img
        src={left}
        alt=""
        className="h-[15vw] w-[15vw] md:h-auto md:w-auto"
      />
    </div>
  );
};

// Custom Next Arrow component
const CustomNextArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="absolute md:right-[12vw] cursor-pointer right-[2.7vw] top-1/2 transform -translate-y-1/2 z-10 text-white rounded-full"
    >
      <img
        src={right}
        alt=""
        className="h-[15vw] w-[15vw] md:h-auto md:w-auto"
      />
    </div>
  );
};

export default LifeSlider;
