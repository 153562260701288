
import React from "react";
import { BsArrowRight } from "react-icons/bs";

export const WhiteBtn = ({ onClick, title,isSticky,navbar }) => {
  return (
    <div

      className={`relative flex cursor-pointer items-center justify-center 
                  md:gap-x-12px-vw gap-x-8px-mvw 
                  overflow-hidden
                  text-[#2B2A29]
                  rounded-[9vw] md:rounded-[2.56vw] 
                  p-14px-mvw md:p-20px-vw 
                  bg-[#FFFFFF] border-[1px] border-[#2B2A29] 
                  shadow-custom !px-16px-mvw md:!px-28px-vw 
                  transition-colors duration-300 ease-in-out 
                hover:text-white  group   ${isSticky && '!bg-[#8C1211] text-[#FFFFFF]' } `}

      onClick={onClick}
    >
      <div className="md:!text-karla-medium-16 text-12px-mvw whitespace-nowrap uppercase font-karla font-medium text-center z-10 ">
        {title}
      </div>


{
  navbar ? (<div className="transition-colors duration-500 ease-in-out group-hover:text-white z-10">

    <BsArrowRight size={25} className="hidden md:text-24px-vw text-18px-mvw" />
  </div>) : (<div className="transition-colors duration-500 ease-in-out group-hover:text-white z-10">

<BsArrowRight size={25} className="md:text-24px-vw text-18px-mvw" />
</div>)
}
      


      <div className="absolute inset-0 bg-[#8C1211] transform -translate-x-full group-hover:translate-x-0 transition-transform duration-700 ease-in-out"></div>

    </div>
  );
};

export default WhiteBtn;
