import React, { useEffect, lazy, Suspense } from "react";
import "./home.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ugmk_prodigies } from "../../lib/homeData";
import AOS from 'aos';
import 'aos/dist/aos.css';
import UgmkAcademy from './section/ugmkAcademy';
import UgmkAbout from './section/ugmkAbout';
import MusicSpiritual from './section/spiritual';
import OurProcess from './section/process';
import StudentPrograms from './section/studentPrograms';
import Testimonial from './testimonial';
import OurCourses from "./OurCourses";
import TestimonialMobile from "./MobileTestimonial";

// Lazy load only UgmkProdigies
const LazyUgmkProdigies = lazy(() => import('./section/ugmkprodigies'));

// Import other components normally

const Home = () => {
  console.log(ugmk_prodigies);
  
  useEffect(() => {
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  return (
    <>
      {/* Render other components normally */}
      <UgmkAcademy />
      <UgmkAbout />
      <MusicSpiritual
        title={'Music is spiritual, it unites people'}
        desc={
          '"when a musician goes in trance, both listeners & musicians get connected to The Almighty"'
        }
      />

      {/* Lazy load UgmkProdigies component */}
      <Suspense fallback={<div>Loading Prodigies...</div>}>
        <LazyUgmkProdigies />
      </Suspense>

      {/* Render other components normally */}
      <OurProcess />
      {/* <StudentPrograms /> */}
      <OurCourses/>
    <div className="md:block hidden">
    <Testimonial />
    </div>
    <div className=" block md:hidden">
      <TestimonialMobile/>
    </div>
    </>
  );
};

export default Home;
