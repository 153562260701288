import "./App.css";
import { AppRoutes } from "./Routes/AppRoutes";
import Header  from "./Components/Header";
import Footer  from "./Components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "./Components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import NewHeader from "./Container/Header/NewHeader";

function App() {
  return (
    <div className="App w-full  !bg-[#FAF6F0]">
      <div className="!hidden">
      <Header /> 
      </div>
      <NewHeader/>
      <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        closeOnClick 
        pauseOnHover 
        draggable 
      />
      <ScrollToTop/>
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;


