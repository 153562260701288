import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import "./termsUse.scss";
import BreadCrumbs from "../../Components/BreadCrumbs";
import TopComponent from "../../Components/TopComponent";

const TermsOfUse = () => {
  return (
    <div>
      <section className="ContactBanner">
        <TopComponent
          title={"Terms Of Use"}
          breadcrumb={"Terms Of Use"} 
          mbImg={"/mobile/mobile_terms.png"}
          img={"/bg/terms.png"}
          mobileTitle={
            <>
              Terms Of <br /> Use
            </>
          }
        />
      </section>
      <section className="TermuseContent">
        <Container className="custom-container">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown
          </p>
          <p>
            {" "}
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries,
          </p>
          <p>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
          <p>Lorem Ipsum has been the industry's standard dummy text ever</p>
          <p>
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <p>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <p>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum an unknown printer took a galley of type and scrambled it to
            make a type specimen book. It has survived not only five centuries,
            but also the leap into electronic typesetting, remaining essentially
            unchanged.
          </p>
          <p>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum
          </p>
        </Container>
      </section>
    </div>
  );
};

export default TermsOfUse;
